@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

// fonts
$Poppins: "Poppins", sans-serif !important;
$Typograph: "TYPOGRAPH PRO", sans-serif !important;

// colors
$dark: rgb(0, 0, 0);
$light: rgb(255, 255, 255);
$primary: #051633;
$secondary: #0b6e3c;
$blue: #4169e1;

@import "~bootstrap/scss/bootstrap";
