@import "../variables.scss";

.hero {
  background-color: #051633;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23181844' stroke-width='5.8'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%2328551E'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
  height: 100vh;
  box-shadow: inset 0 0 2000px rgba(0, 0, 0, 0.5);
  max-width: 100%;
  overflow-x: hidden;
}

.contentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90vh;
  max-width: 100%;
}

.profilePicContainer {
  position: relative;
  height: 300px;
  max-width: 50%;
  display: flex;
  justify-content: flex-end;
  margin-right: 100px;

  .profileWrapper {
    position: relative;
    width: fit-content;
    max-width: 100%;
  }

  .profilePic {
    position: relative;
    width: 250px;
    object-fit: cover;
    border-radius: 95px;
    left: 80px;
    top: -25px;
    opacity: 0;
    animation: fadeIn 3s forwards;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

.btnContainer {
  display: flex;
  align-items: center;
  color: white;
  position: relative;
  height: 50px;
}

.hireBtn {
  appearance: none;
  background-color: $secondary;
  border: 2px solid $secondary;
  border-radius: 15px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
  margin-top: 20px;
  transition: margin-top 0.3s ease;

  &:disabled {
    pointer-events: none;
  }

  &:hover {
    color: #fff;
    background-color: $secondary;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    margin-top: -1px;
  }

  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
}

.portfolioBtn {
  appearance: none;
  background-color: transparent;
  border: 2px solid $secondary;
  border-radius: 15px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  margin-left: 50px;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 20px;
  text-align: center;
  text-decoration: none;
  transition: margin-top 0.3s ease;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
  margin-top: 20px;

  &:disabled {
    pointer-events: none;
  }

  &:hover {
    color: #fff;
    background-color: $secondary;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    margin-top: -1px;
  }

  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slideUp {
  animation: slideFromBottom 1s ease-in-out forwards;
  animation-fill-mode: forwards;
}

.delayed {
  animation-delay: 3s;
}

.blob {
  height: 150px;
  width: 425px;
  position: absolute;
  top: -10%;
  left: 0;
  z-index: 1;
}

.introRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.introText {
  flex-direction: column;
  height: 150px;
  width: 100%;
  position: relative;
}

.intro-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 50%;
}

.h1,
.h2,
.h3 {
  font-family: $Typograph;
  color: white;
  position: relative;
}

.h1 {
  font-weight: bold;
  font-size: 28px;
  top: 0;
  left: 0;
}

.h2 {
  font-weight: bolder;
  font-size: 42px;
  top: 0;
  left: 0;
}

.h3 {
  font-weight: bold;
  font-size: 28px;
  top: 0;
  left: 0;
}

.fadeIn {
  opacity: 0;
  animation: fadeInAnimation 1s ease-in-out forwards;

  @keyframes fadeInAnimation {
    from {
      transform: translateX(-50%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.slideLeft {
  animation: slideFromLeft 1s ease-in-out forwards;
}
@media screen and (max-width: 1371px) and (min-width: 1200px) {
  .h1 {
    font-weight: bold;
    font-size: 24px;
    top: 0;
    left: 0;
  }

  .h2 {
    font-weight: bolder;
    font-size: 32px;
    top: 0;
    left: 0;
  }

  .h3 {
    font-weight: bold;
    font-size: 24px;
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .profilePicContainer .profilePic {
    position: relative;
    width: 250px;
    object-fit: cover;
    border-radius: 95px;
    left: 58px;
    top: -53px;
  }

  .blob {
    height: 150px;
    width: 399px;
    position: absolute;
    top: -10%;
    left: 0;
    z-index: 1;
  }
}

@media screen and (max-width: 991px) {
  .contentContainer {
    flex-direction: column-reverse;
    height: auto;
    align-items: center;
    justify-content: center;
  }

  .profilePicContainer {
    order: 2;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 40px;
  }

  .h1 {
    margin-top: 50px;
  }

  .introText {
    order: 1;
    text-align: center;
    margin-top: 20px;
  }

  .btnContainer {
    order: 3;
    top: 75px;
    display: flex;
    justify-content: center;
  }

  .hireBtn,
  .portfolioBtn {
    margin: 0 10px;
  }
}

@media screen and (max-width: 625px) {
  .profilePicContainer .profilePic {
    position: relative;
    width: 187px;
    object-fit: cover;
    border-radius: 95px;
    left: 43px;
    top: -53px;
  }

  .blob {
    height: 150px;
    width: 399px;
    position: absolute;
    top: -57px;
    left: -25px;
    z-index: 1;
  }

  .blob {
    height: 100px;
    width: 300px;
  }

  .h1 {
    font-size: 18px;
  }

  .h2 {
    font-size: 22px;
  }

  .h3 {
    font-size: 15px;
  }

  .btnContainer {
    order: 3;
    margin-top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .hireBtn,
  .portfolioBtn {
    width: 60%;
    max-width: 100%;
    font-size: 16px;
    margin: 5px;
    padding: 16px;
    margin-top: 10px;
  }

  .hireBtn {
    text-align: center;
    margin-top: 25px;

    &:hover {
      margin-top: 25px;
    }
  }

  .portfolioBtn {
    margin-top: 25px;

    &:hover {
      margin-top: 25px;
    }
  }
}

@media screen and (min-width: 625px) and (max-width: 769px) {
  .profilePicContainer .profilePic {
    position: relative;
    width: 187px;
    object-fit: cover;
    border-radius: 95px;
    left: 43px;
    top: -53px;
  }

  .blob {
    height: 150px;
    width: 399px;
    position: absolute;
    top: -57px;
    left: -25px;
    z-index: 1;
  }

  .blob {
    height: 100px;
    width: 300px;
  }

  .h1 {
    font-size: 18px;
  }

  .h2 {
    font-size: 22px;
  }

  .h3 {
    font-size: 15px;
  }

  .btnContainer {
    top: 50px;
  }
}

@media screen and (max-width: 385px) {
  .blob {
    display: none;
  }

  .profilePicContainer .profilePic {
    width: 100%;
  }

  .h1 {
    margin-top: 1px;
  }

  .portfolioBtn {
    font-size: 12px;
  }

  .btnContainer {
    top: 0;
  }
}
