@import "../variables.scss";

.footer {
  background-color: $secondary;
  color: white;
  padding: 20px;
}

.iconContainer {
  display: flex;
  justify-content: center;
}

.iconBg {
  padding: 8px;
  margin: 10px;
  border-radius: 100px;
  border: 1px solid $dark;
  background-color: $primary;
  border: 1px solid rgb(0 0 0);
  box-shadow: rgb(220 220 229 / 25%) 0px 7px 29px 0px;

  &:hover {
    transition: 0.3s ease;
    background-color: transparent;
    border: 1px solid $primary;
  }
}

.icon {
  height: 32px;
  width: 32px;
}
