@import "../variables.scss";

.portfolioBody {
  height: auto;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: $primary;
}

.titleContainer {
  padding-top: 100px;
  color: white;
}

.hr {
  margin: 30px 0;
  height: 2px;
  border: none;
  background: -webkit-gradient(
    linear,
    0 0,
    100% 0,
    from(transparent),
    to(transparent),
    color-stop(50%, $secondary)
  );
}
