@import "../variables.scss";

.portfolioContainer {
  height: auto;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.portfolioCard {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.portfolioImg {
  height: 350px;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.boxImg {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  box-shadow: rgba(19, 17, 116, 0.2) 0px 2px 8px 0px;
  transition: all 0.3s ease-in;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1;
    background: linear-gradient(45deg, #051633ed, #0b6e3ce8);
  }
}

.boxContent {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.iconBox {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.iconBox a {
  display: block;
  padding: 10px;
}

.circle {
  border-radius: 50%;
}

.githubIcon,
.webIcon {
  position: absolute;
  width: 50px;
  opacity: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
    width 0.3s ease-in-out, height 0.3s ease-in-out;
  transform-origin: center center;
}

.githubIcon {
  top: 10px;
  right: 10px;
  background-color: rgb(21, 162, 21);

  &:hover {
    transform: scale(1.1);
  }
}

.webIcon {
  bottom: 10px;
  left: 10px;
  background-color: $blue;

  &:hover {
    transform: scale(1.1);
  }
}

.boxImg:hover .githubIcon,
.boxImg:hover .webIcon {
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 5px 15px;
}

.imgLink {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
}

.texts {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  cursor: default;
}

.boxImg:hover .texts {
  opacity: 1;
  top: 50%;
}

.texts {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  z-index: 2;
  transition: opacity 0.5s ease-in-out;
}

.textContent {
  position: relative;
  z-index: 2;
}

.title {
  font-size: 1.5em;
  margin-bottom: 10px;

  &:hover {
    z-index: 2;
  }
}

.description {
  font-size: 1em;
  opacity: 1;

  &:hover {
    z-index: 2;
  }
}

.fadeIn {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  transform: translateY(-30px);
}

.visible {
  opacity: 1 !important;
  transform: translateY(0) !important;
}
