@import "~bootstrap/scss/bootstrap";
@import "./variables.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;

}

body {
  font-family: $Poppins;
  color: $dark;
  min-height: 100vh;
}
