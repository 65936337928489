@import "../variables.scss";

.contactBody {
  min-height: 85vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 25px;

  .title {
    margin-top: 50px;
  }

  .messageBox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }

  .centeredBox {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    height: 200px;
  }

  .messageContent {
    text-align: center;
    font-weight: bold;
  }

  .secondaryButton {
    background-color: $secondary;
    color: white;
    font-weight: bold;
    padding: 10px;

    &:hover {
      background-color: rgb(3, 71, 3);
    }
  }

  .button {
    padding: 15px;
    font-weight: bold;
  }
}

.mapContainer {
  width: 100%;
  height: 400px;
}

.mapCol {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  overflow: hidden;

  &.slideInLeft {
    opacity: 1;
    transform: translateX(0);
    overflow: hidden;
  }
}

.formCol {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  overflow: hidden;

  &.slideInRight {
    opacity: 1;
    transform: translateX(0);
    overflow: hidden;
  }

  @media screen and (max-width: 655px) {
    transform: translateX(0);
  }
}

.flexContainer {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 767px) {
  .flexContainer {
    flex-direction: row;
  }

  .contactBody {
    top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .mapCol {
    height: 500px;
    margin-top: 25px;
  }
}
